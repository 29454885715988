import Api from '../../plugins/baseAPI'

const state = {
    products: [],
};

const getters = {
    productsList: state => state.products,
};

const actions = {
    getProducts({commit}, user) {
        return new Promise((resolve, reject) => {
            Api().get("products", user)
                .then(resp => {
                    commit('setProducts', resp.data)
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getProduct({commit}, pid) {
        return new Promise((resolve, reject) => {
            Api().get("product/"+pid)
                .then(resp => {
                    commit('updateProduct', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    setProducts(state, payload) {
        state.products = payload
    },
    updateProduct(state, payload) {
        const item = state.products.find(item => item.id === payload.id);
        Object.assign(item, payload);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};