import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content-vertical-nav',{attrs:{"nav-menu-items":_vm.navMenuItems},scopedSlots:_vm._u([{key:"navbar",fn:function(ref){
var isVerticalNavMenuActive = ref.isVerticalNavMenuActive;
var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive;
return [_c('div',{staticClass:"navbar-content-container",class:{'expanded-search': _vm.shallShowFullSearch}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c(VIcon,{staticClass:"me-3",on:{"click":toggleVerticalNavMenuActive}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center right-row"},[_c('app-bar-user-menu')],1)])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v("COPYRIGHT © "+_vm._s(new Date().getFullYear())+" "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://themeselection.com"}},[_vm._v("ThemeSelection")]),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(", All rights Reserved")])]),_c('div',{staticClass:"align-center d-none d-md-flex"},[_c('span',[_vm._v("Hand-crafted & Made with")]),_c(VIcon,{staticClass:"ms-2",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiHeartOutline)+" ")])],1)])]},proxy:true}])},[_vm._t("default"),_c(VSnackbar,{attrs:{"timeout":2500,"value":!!_vm.snackbar.message,"right":"","top":"","color":_vm.snackbar.type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$store.commit('RESET_ALERT')}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }