// eslint-disable-next-line import/no-cycle
import Api from '@/plugins/baseAPI'

const state = {
  notifications: [],
  lastNotificationId: 1,
}

const getters = {
  userNotifications: state => state.notifications,
  lastNotificationId: state => state.lastNotificationId,
}

const actions = {

  askLastNotification({ state, commit, user }) {
    return new Promise((resolve, reject) => {
      Api().get('notifications/ask', user)
        .then(resp => {
          if (resp.data.id > 0) {
            if (resp.data.id > state.lastNotificationId) {
              commit('setLastNotificationId', resp.data.id)
            }
          }
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getNotifications({ commit, user }) {
    return new Promise((resolve, reject) => {
      Api().get('notifications', user)
        .then(resp => {
          if (resp.data.length > 0) {
            resp.data.sort((a, b) => {
              if (a.is_read < b.is_read) {
                return -1
              }
              if (a.is_read > b.is_read) {
                return 1
              }

              return 0
            })
          }

          commit('setNotifications', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setNotifications(state, payload) {
    state.notifications = payload
  },
  setLastNotificationId(state, payload) {
    state.lastNotificationId = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
