import Api from '../../plugins/baseAPI'

const state = {
  files: [],
}

const getters = {
  groupFilesList: state => state.files,
}

const actions = {
  getGroupFiles({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('ext/group-files')
        .then(resp => {
          commit('setGroupFiles', resp.data.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getGroupFile({ commit }, id) {
    return new Promise((resolve, reject) => {
      Api().get(`ext/group-files/${id}`)
        .then(resp => {
          commit('updateGroupFile', resp.data.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setGroupFiles(state, payload) {
    state.files = payload
  },
  updateGroupFile(state, payload) {
    const item = state.files.find(item => item.id === payload.id)
    Object.assign(item, payload)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}