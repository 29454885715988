const state = {
    breadCrumb: []
};

const getters = {
    breadCrumb: state => state.breadCrumb,
};

const actions = {
    setBreadCrumbLevels({commit},breadData) {
        commit('setBreadCrumb', breadData);
    }
};

const mutations = {
    setBreadCrumb: (state, resp) => {
        state.breadCrumb = resp;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};