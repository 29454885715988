import Api from '../../plugins/baseAPI'

const state = {
  invoices: [],
  license: [],
  companyAvatar: '',
}

const getters = {
  invoicesList: state => state.invoices,
  licenseStatus: state => state.license,
  companyAvatar: state => state.companyAvatar,
}

const actions = {
  getInvoices({ commit }, user) {
    return new Promise((resolve, reject) => {
      Api().get('invoices', user)
        .then(resp => {
          commit('setInvoices', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getLicense({ commit }, user) {
    return new Promise((resolve, reject) => {
      Api().get('license', user)
        .then(resp => {
          commit('setLicense', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCompanyAvatar({ commit }, cid) {
    return new Promise((resolve, reject) => {
      Api().get(`company-avatar/${cid}`)
        .then(resp => {
          commit('setCompanyAvatar', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setInvoices(state, payload) {
    state.invoices = payload
  },
  setLicense(state, payload) {
    state.license = payload
  },
  setCompanyAvatar(state, payload) {
    state.companyAvatar = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
