<template>
  <layout-blank>
    <slot></slot>
    <v-snackbar
      :timeout="2500"
      :value="!!snackbar.message"
      right
      top
      :color="snackbar.type"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="$store.commit('RESET_ALERT')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import { mapState } from 'vuex'

export default {
  components: {
    LayoutBlank,
  },
  computed: {
    ...mapState(['snackbar']),
  },
}
</script>

<style>
</style>
