let apiEnvUrl = ''
if (window.location.href.includes('.local') || window.location.href.includes('localhost') || window.location.href.includes('192.168.68')) {
  apiEnvUrl = 'http://api.lamiapraticaonline.it.local/api/'
} else if (window.location.href.includes('test')) {
  apiEnvUrl = 'https://test.api.lamiapraticaonline.it/api/'
} else {
  apiEnvUrl = 'https://api.lamiapraticaonline.it/api/'
}

const stripeSecret = 'pk_test_51JSTNoFX8tabHus4y0RjVDIODEUgbjvLwh8csFwa1fwOe4JjQ9xJa79NekN0AHVyRwpvGMEE7caXGd7Qj6oJU8dl00XDJNQffZ'

export { apiEnvUrl, stripeSecret }
