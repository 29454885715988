import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import vClickOutside from 'v-click-outside'
import { apiEnvUrl } from '@/configs/api'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/acl'
import API from './plugins/api'

Vue.use(vClickOutside)

setTimeout(() => {
  Vue.prototype.$apiUrl = apiEnvUrl
  Vue.prototype.$api = API
  Vue.config.productionTip = false

  Vue.filter('toCurrency', value => {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    })

    return formatter.format(value)
  })

  Vue.filter('timestampToDate', value => {
    if (typeof value !== 'number') {
      return value
    }
    const timestamp = { seconds: value, nanoseconds: 0 } // firebase data
    const date = new Date(timestamp.seconds * 1000) // date object

    return date.toLocaleDateString('it-IT')
  })

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
}, 50)
