<template>
  <div>
       <div class="my-7 text-center">
            <v-btn @click="$router.push('/')" outlined icon>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h1>Page Not Found</h1>
        </div>
    </div>

</template>

<script>

export default {
} 
</script>