import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/views/dashboard.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login_orig.vue'),
    meta: {
      layout: 'blank',
      noRedirectIfNotLoggedIn: true,
    },
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('@/views/refresh.vue'),
    meta: {
      layout: 'blank',
      noRedirectIfNotLoggedIn: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/settings/profile.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/notification.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/settings/company.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/archived-procedures',
    name: 'proceduresArchived',
    component: () => import('../views/archived-procedure/index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/archived-procedure/:id/folder/:fid',
    name: 'procedureArchivedFolderView',
    component: () => import('../views/archived-procedure/folder.vue'),
    meta: {
      layout: 'content',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/archived-procedure/:id/view',
    name: 'archivedProcedureView',
    component: () => import('../views/archived-procedure/view.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/archived-procedure/:id/documents/:did',
    name: 'archivedProcedureDocumentsView',
    component: () => import('../views/archived-procedure/document.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/procedures',
    name: 'procedures',
    component: () => import('../views/procedure/index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/procedure/:id/folder/:fid',
    name: 'procedureFolderView',
    component: () => import('../views/procedure/folder.vue'),
    meta: {
      layout: 'content',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/procedure/:id/view',
    name: 'procedureView',
    component: () => import('../views/procedure/view.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/procedure/:id/documents/:did',
    name: 'procedureDocumentsView',
    component: () => import('../views/procedure/document.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/procedure/:id/surveys/:sid',
    name: 'procedureSurveysView',
    component: () => import('../views/procedure/survey.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/procedure-templates',
    name: 'procedureTemplates',
    component: () => import('../views/procedureTemplate/index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/procedure-template/:id/view',
    name: 'procedureTemplateView',
    component: () => import('../views/procedureTemplate/view.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/company-documents',
    name: 'companyDocuments',
    component: () => import('../views/company/documents.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/company-document/:cid/documents/:did',
    name: 'companyDocumentFiles',
    component: () => import('../views/company/files.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/group-documents',
    name: 'groupDocuments',
    component: () => import('../views/group/documents.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/group-document/:did',
    name: 'groupDocumentFiles',
    component: () => import('../views/group/files.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/group-folder/:id/folder/:fid',
    name: 'groupFolderView',
    component: () => import('../views/group/folder.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user-documents/:uid',
    name: 'userDocuments',
    component: () => import('../views/user/documents.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user-document/:uid/documents/:did',
    name: 'userDocumentFiles',
    component: () => import('../views/user/files.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/subscriptions/orders.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('../views/subscriptions/subscription.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../views/subscriptions/invoices.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/company/customers.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/customer/:cid',
    name: 'customerDetail',
    component: () => import('../views/company/customer_detail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tickets',
    name: 'customerTickets',
    component: () => import('../views/support/tickets.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/ticket/:tid',
    name: 'customerTicketDetail',
    component: () => import('../views/support/ticket_detail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/configs',
    name: 'configs',
    component: () => import('../views/settings/configs.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('../views/refresh.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
      noRedirectIfNotLoggedIn: true,
    },
  },
  {
    path: '/register-complete/:vs',
    name: 'register-complete',
    component: () => import('../views/register-confirm.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
      noRedirectIfNotLoggedIn: true,
    },
  },
  {
    path: '/invite-page/:tk',
    name: 'invite-page',
    component: () => import('../views/invites/invite.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/add-page/:tk',
    name: 'add-page',
    component: () => import('../views/invites/add.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/company-invite-page/:tk',
    name: 'company-invite-page',
    component: () => import('../views/invites/inviteIntoCompany.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/invite-page-professional/:tk',
    name: 'invite-page-professional',
    component: () => import('../views/invites/inviteProfessional.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/password-reminder',
    name: 'password-reminder',
    component: () => import('../views/password-reminder.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/password-reminder-set/:ps',
    name: 'password-reminder-set',
    component: () => import('../views/password-reminder-set.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/plugin-procedure/:id/view',
    name: 'pluginProcedureView',
    component: () => import('../views/plugin/plugin-procedure.vue'),
    meta: {
      layout: 'blank',
      appType: 'plugin',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/plugin-procedure/:id/documents/:did',
    name: 'pluginProcedureDocumentsView',
    component: () => import('../views/plugin/plugin-procedure-document.vue'),
    meta: {
      layout: 'blank',
      appType: 'plugin',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/plugin-procedure/:id/folder/:fid',
    name: 'pluginProcedureFolderView',
    component: () => import('../views/plugin/plugin-folder.vue'),
    meta: {
      layout: 'blank',
      appType: 'plugin',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/plugin-group-folder/:id/folder/:fid',
    name: 'pluginGroupFolderView',
    component: () => import('../views/plugin/plugin-group-folder.vue'),
    meta: {
      layout: 'blank',
      appType: 'plugin',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/plugin-group-documents',
    name: 'pluginGroupDocuments',
    component: () => import('../views/plugin/plugin-group.vue'),
    meta: {
      layout: 'blank',
      appType: 'plugin',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/plugin-group-documents/:did',
    name: 'pluginGroupDocumentFiles',
    component: () => import('../views/plugin/plugin-group-files.vue'),
    meta: {
      layout: 'blank',
      appType: 'plugin',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  // qui va gestito il permesso del ruolo
  const { isAuthenticated } = store.getters

  if (!isAuthenticated && !to.meta.noRedirectIfNotLoggedIn) {
    if (to.meta.layout === 'content') {
      // return next({ name: 'login' })
    }
    if (to.meta.layout === 'blank') {
      if (to.meta.appType === 'plugin') {
        // if there is a query param named 'token' then it's a plugin and we can go on, eventually the page will be reloaded
        if (to.query.token) {
          return next()
        }

        // courtesy page for reload, temporary redirect to login
        return next({ name: 'refresh' })
      }

      // return next({ name: 'login' })
    }
  }

  return next()
})

export default router
