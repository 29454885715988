import Api from '../../plugins/baseAPI'

const state = {
  documents: [],
}

const getters = {
  groupDocumentsList: state => state.documents,
}

const actions = {
  getGroupDocuments({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('ext/group-documents')
        .then(resp => {
          commit('setGroupDocuments', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getGroupDocument({ commit }, id) {
    return new Promise((resolve, reject) => {
      Api().get(`ext/group-documents/${id}`)
        .then(resp => {
          commit('updateGroupDocument', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setGroupDocuments(state, payload) {
    state.documents = payload
  },
  updateGroupDocument(state, payload) {
    const item = state.documents.find(item => item.id === payload.id)
    Object.assign(item, payload)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
