import Api from '../../plugins/baseAPI'

const state = {
  subscriptions: [],
  current_subscription: {},
  product_config: [],
}

const getters = {
  subscriptionsList: state => state.subscriptions,
  currentSubscription: state => state.current_subscription,
  productConfig: state => state.product_config,
}

const actions = {
  getSubscriptions({ commit }, user) {
    return new Promise((resolve, reject) => {
      Api().get('subscriptions', user)
        .then(resp => {
          commit('setSubscriptions', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSubscription({ commit }, sid) {
    return new Promise((resolve, reject) => {
      Api().get(`subscription/${sid}`)
        .then(resp => {
          commit('updateSubscription', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCurrentSubscription({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('/subscription/current')
        .then(resp => {
          if (resp.data.status !== 'no_data' && resp.data.status !== 'error') {
            commit('setCurrentSubscription', resp.data)
            commit('setProductConfig', resp.data.productConfig.product_config)
          } else {
            commit('setCurrentSubscription', {})
            commit('setProductConfig', [])
          }
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setSubscriptions(state, payload) {
    state.subscriptions = payload
  },
  updateSubscription(state, payload) {
    const item = state.subscriptions.find(item => item.id === payload.id)
    Object.assign(item, payload)
  },
  setCurrentSubscription(state, payload) {
    state.current_subscription = payload
  },
  setProductConfig(state, payload) {
    state.product_config = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
