import Api from '../../plugins/baseAPI'

const state = {
  userInfo: [],
  userAvatar: '',
  userPreferences: [],
  companyInfo: [],
  groupInfo: [],
}

const getters = {
  loggedUser: state => state.userInfo,
  loggedUserAvatar: state => state.userAvatar.img,
  loggedUserPreferences: state => state.userPreferences,
  loggedUserCompany: state => state.companyInfo,
  loggedUserGroup: state => state.groupInfo,
}

const actions = {
  getUserInfoAll({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('profile')
        .then(resp => {
          commit('setUserInfo', resp.data)
          commit('setUserPreferences', resp.data.user_preferences)
          commit('setUserCompany', resp.data.company)
          commit('setUserGroup', resp.data.company.group)

          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPluginUserInfoAll({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('/ext/profile')
        .then(resp => {
          commit('setUserInfo', resp.data)
          commit('setUserPreferences', resp.data.user_preferences)
          commit('setUserCompany', resp.data.company)
          commit('setUserGroup', resp.data.company.group ?? {})

          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('profile')
        .then(resp => {
          commit('setUserInfo', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserAvatar({ commit }, uid) {
    return new Promise((resolve, reject) => {
      Api().get(`user-avatar/${uid}`)
        .then(resp => {
          commit('setUserAvatar', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserPreferences({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('profile/preferences')
        .then(resp => {
          commit('setUserPreferences', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCompanyInfo({ commit }, id) {
    return new Promise((resolve, reject) => {
      Api().get(`company/${id}`)
        .then(resp => {
          commit('setUserCompany', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getGroupInfo({ commit }, id) {
    return new Promise((resolve, reject) => {
      Api().get(`group/${id}`)
        .then(resp => {
          commit('setUserGroup', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

}

const mutations = {
  setUserInfo: (state, resp) => {
    state.userInfo = resp
  },
  setUserAvatar: (state, resp) => {
    state.userAvatar = resp
  },
  setUserPreferences: (state, resp) => {
    state.userPreferences = resp
  },
  setUserCompany: (state, resp) => {
    state.companyInfo = resp
  },
  setUserGroup: (state, resp) => {
    state.groupInfo = resp
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
