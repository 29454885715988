<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="loggedUserAvatar"
            :src="loggedUserAvatar"
          />
          <span
            v-else
            class="text-h7"
          >
            {{ loggedUser.initials }}
          </span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              :src="loggedUserAvatar"
            />
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ loggedUser.fullName }}
          </span>
          <small class="text--disabled text-capitalize">Admin</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item to="/profile">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profilo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Company -->
      <v-list-item to="/company">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiDomain }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Azienda</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Email -->
      <!--      <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->

      <v-divider class="my-2"></v-divider>

      <!-- Chat -->
      <v-list-item to="/tickets">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Tickets</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action>
      </v-list-item>

      <!-- Settings -->
      <v-list-item to="/configs">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Impostazioni</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click.prevent="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiDomain,
} from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiDomain,
      },
    }
  },
  data() {
    return {
      drawer: true,
      notificationsMenu: false,
      proCreate: false,
      proTempCreate: false,
      user: [],
      maxNotifications: 5,
      notificationBadge: false,
      loading: {
        reloadData: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated', 'loggedUser', 'loggedUserAvatar', 'loggedUserCompany',
      'userNotifications', 'lastNotificationId', 'licenseStatus', 'breadCrumb', 'productFunctionalities',
      'loadDataInterval', 'getImagesRepo',
    ]),
    last5Notifications() {
      if (this.userNotifications.length > 0) {
        return this.userNotifications.slice(0, this.maxNotifications)
      }

      return false
    },
  },
  watch: {
    async isAuthenticated() {
      if (!this.isAuthenticated) {
        await this.pushLogout()
      }
    },
    lastNotificationId() {
      this.notificationBadge = true
      this.$store.dispatch('getNotifications')
      this.reloadData()
    },
    breadCrumb() {
      if (this.loggedUser.role === 'professional' && this.loggedUser.company_role === 'owner') {
        if (this.licenseStatus.status !== 'success') {
          if (this.$route.name !== 'subscription') {
            this.$router.push('/subscription')
          }
        }
      }
    },
  },
  async mounted() {
    if (!this.loggedUserAvatar) {
      await this.getImagePath()
    }

    await this.checkNotifications(true)

    // check every X seconds for updates
    const refreshNotification = setInterval(() => {
      if (this.isAuthenticated) {
        this.checkNotifications(false)
      } else {
        clearInterval(refreshNotification)
      }
    }, this.loadDataInterval)
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      await this.pushLogout()
    },
    async pushLogout() {
      await this.$router.push({ name: 'login' })
    },

    /* async getImagePath() {
      try {
        if(this.getImagesRepo['userAvatars'][this.loggedUser.id]){
          let userAvatar = this.getImagesRepo['userAvatars'][this.loggedUser.id];
          let date = new Date
          if(Math.floor( date.getTime() / 1000) > userAvatar.expires){
            await this.askImagePath()
          }else{
            this.loggedUserAvatar = userAvatar.url
          }

        }else{
          await this.askImagePath()
        }

      } catch (error) {
        console.log(error);
      }
    },
    async askImagePath() {
      try {
        let resp = await this.$api.getUserAvatar(this.loggedUser.id)
        this.loggedUserAvatar = resp.data.img

        await this.$store.dispatch('setImageRepo', {
          'repo'  : 'userAvatars',
          'id'    : this.loggedUser.id,
          'imageUrl'  : resp.data.img,
          'expires'   : resp.data.expires
        });
      } catch (error) {
        console.log(error);
      }
    }, */
    async getImagePath() {
      this.loggedUserAvatar = this.$store.getters.getImagePath('userAvatars', this.loggedUser.id)
    },
    async checkNotifications(firstExec) {
      await this.$store.dispatch('askLastNotification')
      if (firstExec) {
        await this.$store.dispatch('getNotifications')
        await this.checkNotificationBadge()
      }
    },
    async setNotificationToRead(id, isRead) {
      if (isRead === 'no') {
        try {
          await this.$api.setNotificationToRead(id)
          await this.$store.dispatch('getNotifications')
        } catch (e) {
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    resetNotificationBadge() {
      this.notificationBadge = false
    },
    checkNotificationBadge() {
      const filtered = this.userNotifications.filter(n => n.is_read === 'no')

      this.notificationBadge = filtered.length > 0
    },
    async reloadData() {
      try {
        this.loading.reloadData = true
        await this.$store.dispatch('reload_specific_data')
        this.loading.reloadData = false
      } catch (e) {
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        this.loading.reloadData = false
      }
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
