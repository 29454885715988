import {
  mdiCardAccountDetailsOutline,
  mdiClipboardMultipleOutline, mdiCog,
  mdiFileOutline,
  mdiHomeOutline,
  mdiPackageVariantClosed, mdiTicketAccount,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'DashboardMenu',
    action: 'read',
  },
  {
    title: 'Le mie pratiche',
    icon: mdiFileOutline,
    resource: 'ProceduresMenu',
    action: 'read',
    children: [
      {
        title: 'In corso',
        to: 'procedures',
        resource: 'ProceduresMenuActive',
        action: 'read',
      },
      {
        title: 'Archiviate',
        to: 'proceduresArchived',
        resource: 'ProceduresMenuArchived',
        action: 'read',
      },
    ],
  },
  {
    title: 'I miei modelli',
    icon: mdiClipboardMultipleOutline,
    resource: 'ProceduresTemplateMenu',
    action: 'read',
    children: [
      {
        title: 'Vedi elenco',
        to: 'procedureTemplates',
        resource: 'ProceduresTemplateMenuActive',
        action: 'read',
      },
    ],
  },
  {
    title: 'Archivio',
    icon: mdiPackageVariantClosed,
    resource: 'ArchiveMenu',
    action: 'read',
    children: [
      {
        title: 'Clienti',
        to: 'customers',
        resource: 'ArchiveCustomersMenu',
        action: 'read',
      },
      {
        title: 'Documenti aziendali',
        to: 'companyDocuments',
        resource: 'ArchiveCompanyDocumentsMenu',
        action: 'read',
      },
      {
        title: 'Documenti gruppo',
        to: 'groupDocuments',
        resource: 'ArchiveGroupDocumentsMenu',
        action: 'read',
      },
      {
        title: 'Documenti Personali',
        to: 'userDocuments',
        resource: 'ArchiveUserDocumentsMenu',
        action: 'read',
      },
    ],
  },
  {
    title: 'Abbonamento',
    icon: mdiCardAccountDetailsOutline,
    resource: 'SubscriptionMenu',
    action: 'read',
    children: [
      {
        title: 'Licenza',
        to: 'subscription',
        resource: 'SubscriptionLicenseMenu',
        action: 'read',
      },
      {
        title: 'Ordini',
        to: 'orders',
        resource: 'SubscriptionOrdersMenu',
        action: 'read',
      },
      {
        title: 'Fatture',
        to: 'invoices',
        resource: 'SubscriptionInvoicesMenu',
        action: 'read',
      },
    ],
  },
  {
    title: 'Impostazioni',
    icon: mdiCog,
    resource: 'SettingsMenu',
    action: 'read',
    children: [
      {
        title: 'Il mio profilo',
        to: 'profile',
        resource: 'SettingsProfileMenu',
        action: 'read',
      },
      {
        title: 'La mia attività',
        to: 'company',
        resource: 'SettingsCompanyMenu',
        action: 'read',
      },
      {
        title: 'Configurazioni',
        to: 'configs',
        resource: 'SettingsConfigsMenu',
        action: 'read',
      },
    ],
  },
  {
    title: 'Supporto',
    icon: mdiTicketAccount,
    resource: 'SupportMenu',
    action: 'read',
    children: [
      {
        title: 'Ticket Assistenza',
        to: 'customerTickets',
        resource: 'SupportTicketsMenu',
        action: 'read',
      },
    ],
  },
]
