import Api from './baseAPI'

export default {
  createProcedure(data) {
    return Api().post('procedures', data)
  },
  getProcedure(id) {
    return Api().get(`procedures/${id}`)
  },
  editProcedure(id, data) {
    return Api().put(`procedures/${id}`, data)
  },
  archiveProcedure(id) {
    return Api().post(`procedures/${id}/archive`)
  },
  reactivateProcedure(id) {
    return Api().post(`procedures/${id}/reactivate`)
  },
  editFolder(id, fid, data) {
    return Api().put(`procedure-folder/${id}/folder/${fid}`, data)
  },
  destroyFolder(id, fid, data) {
    return Api().delete(`procedure-folder/${id}/folder/${fid}`, data)
  },
  createFolderGroup(id, data) {
    return Api().post(`group-folder/${id}`, data)
  },
  editFolderGroup(id, fid, data) {
    return Api().put(`group-folder/${id}/folder/${fid}`, data)
  },
  destroyFolderGroup(id, fid, data) {
    return Api().delete(`group-folder/${id}/folder/${fid}`, data)
  },
  getDocument(id, did) {
    return Api().get(`procedures/${id}/documents/${did}`)
  },
  editDocument(id, did, data) {
    return Api().put(`procedures/${id}/documents/${did}`, data)
  },
  destroyDocument(id, did, data) {
    return Api().delete(`procedures/${id}/documents/${did}`, data)
  },
  confirmDocument(id, did, data) {
    return Api().post(`procedures/${id}/documents/${did}/confirm`, data)
  },
  createProcedureTemplate(data) {
    return Api().post('procedure-template', data)
  },
  getProcedureTemplate(id) {
    return Api().get(`procedure-template/${id}`)
  },
  editProcedureTemplate(id, data) {
    return Api().put(`procedure-template/${id}`, data)
  },
  destroyProcedureTemplate(id) {
    return Api().delete(`procedure-template/${id}`)
  },
  createDocProcedureTemplate(id, data) {
    return Api().post(`procedure-template/${id}/documents`, data)
  },
  editDocumentTemplate(id, did, data) {
    return Api().put(`procedure-template/${id}/documents/${did}`, data)
  },
  destroyDocumentTemplate(id, did, data) {
    return Api().delete(`procedure-template/${id}/documents/${did}`, data)
  },
  createDocProcedure(id, data) {
    return Api().post(`procedures/${id}/documents`, data)
  },
  importProcedureTemplate(id, data) {
    return Api().post(`procedure-template/${id}/use`, data)
  },
  createFolderProcedure(id, data) {
    return Api().post(`procedure-folder/${id}`, data)
  },
  downloadFile(pid, did, fid) {
    return Api().get(`procedures/${pid}/documents/${did}/files/${fid}`)
  },
  updateProfile(data) {
    return Api().put('profile', data)
  },
  updateProfilePreferences(data) {
    return Api().put('profile/preferences', data)
  },
  inviteUser(id, data) {
    return Api().post(`procedures/${id}/invite-user`, data)
  },
  readInvite(tk) {
    return Api().get(`invite/${tk}`)
  },
  registerInvite(tk, data) {
    return Api().post(`register-invite/${tk}`, data)
  },
  registerAdd(tk, data) {
    return Api().post(`register-add/${tk}`, data)
  },
  getNotifications(data) {
    return Api().get('notifications', data)
  },
  setNotificationToRead(id) {
    return Api().put(`notifications/${id}/read`)
  },
  createCompany(data) {
    return Api().post('company', data)
  },
  updateCompany(id, data) {
    return Api().put(`company/${id}`, data)
  },
  createDocCompany(data) {
    return Api().post('company-documents', data)
  },
  editCompanyDocument(did, data) {
    return Api().put(`company-documents/${did}`, data)
  },
  destroyCompanyDocument(did, data) {
    return Api().delete(`company-documents/${did}`, data)
  },
  importDefaultDocsIntoProcedure(pid, data) {
    return Api().post(`default-documents/import-default/${pid}`, data)
  },
  getAutoImportDocuments(pid) {
    return Api().get(`company-documents/auto-import-documents/${pid}`)
  },
  optionalImportDocuments(pid, data) {
    return Api().post(`company-documents/optional-import-documents/${pid}`, data)
  },
  createDocUser(data) {
    return Api().post('user-documents', data)
  },
  editUserDocument(did, data) {
    return Api().put(`user-documents/${did}`, data)
  },
  destroyUserDocument(did, data) {
    return Api().delete(`user-documents/${did}`, data)
  },
  renewProduct(data, cid) {
    return Api().post(`company/purchase/${cid}`, data)
  },
  suspendSubscription(sid) {
    return Api().delete(`subscription/${sid}`)
  },
  reactivateSubscription(sid) {
    return Api().put(`subscription/${sid}/reactivate`)
  },
  cancelSubscription(sid) { // TEST METHOD
    return Api().delete(`subscription/${sid}/hard`)
  },
  inviteProfessionalIntoCompany(cid, data) {
    return Api().post(`company/${cid}/invite`, data)
  },
  readCompanyInvite(tk) {
    return Api().get(`company/invite/${tk}`)
  },
  registerCompanyInvite(tk, data) {
    return Api().post(`register-invite/company/${tk}`, data)
  },
  inviteProfessional(id, data) {
    return Api().post(`procedures/${id}/invite-professional`, data)
  },
  registerInviteProfessional(tk, data) {
    return Api().post(`register-invite-professional/${tk}`, data)
  },
  destroyFile(id, did, fid, data) {
    return Api().delete(`procedures/${id}/documents/${did}/files/${fid}`, data)
  },
  createTicket(data) {
    return Api().post('tickets', data)
  },
  createTicketMessage(data, tid) {
    return Api().post(`ticket/${tid}/message`, data)
  },
  confirmAccount(vs) {
    return Api().post(`confirm-account/${vs}`)
  },
  passwordReminder(data) {
    return Api().post('password-reminder', data)
  },
  passwordReminderSet(data, ps) {
    return Api().post(`password-reminder-set/${ps}`, data)
  },
  createSurveyProcedure(id, data) {
    return Api().post(`procedures/${id}/surveys`, data)
  },
  editSurvey(id, sid, data) {
    return Api().put(`procedures/${id}/surveys/${sid}`, data)
  },
  destroySurvey(id, sid, data) {
    return Api().delete(`procedures/${id}/surveys/${sid}`, data)
  },
  createSurveyElement(id, sid) {
    return Api().post(`procedures/${id}/surveys/${sid}/elements`)
  },
  editSurveyElement(id, sid, eid, data) {
    return Api().put(`procedures/${id}/surveys/${sid}/elements/${eid}`, data)
  },
  deleteSurveyElement(id, sid, eid) {
    return Api().delete(`procedures/${id}/surveys/${sid}/elements/${eid}`)
  },
  editSurveyElementIndex(id, sid, eid, data) {
    return Api().put(`procedures/${id}/surveys/${sid}/elements/${eid}/updateIndex`, data)
  },
  createSurveySubElement(id, sid, eid) {
    return Api().post(`procedures/${id}/surveys/${sid}/elements/${eid}/subElements`)
  },
  deleteSurveySubElement(id, sid, eid, seid) {
    return Api().delete(`procedures/${id}/surveys/${sid}/elements/${eid}/subElements/${seid}`)
  },
  saveSurveyResponses(id, sid, data) {
    return Api().post(`procedures/${id}/surveys/${sid}/responses`, data)
  },
  createSetting(data) {
    return Api().post('company-settings-values', data)
  },
  editSetting(sid, data) {
    return Api().put(`company-settings-values/${sid}`, data)
  },
  disableSetting(sid) {
    return Api().delete(`company-settings-values/${sid}`)
  },
  reactivateSetting(sid) {
    return Api().put(`company-settings-values/${sid}/reactivate`)
  },
  getDocumentFileThumb(id, did, fid) {
    return Api().get(`procedures/${id}/documents/${did}/files/${fid}/thumb`)
  },
  getUserAvatar(uid) {
    return Api().get(`user-avatar/${uid}`)
  },
  indexSubgroups() {
    return Api().get('subgroups')
  },
  getFolderPermissions(pid, fid) {
    return Api().get(`procedure-folder/${pid}/folder/${fid}/permissions`)
  },
  setFolderPermissions(pid, fid, data) {
    return Api().post(`procedure-folder/${pid}/folder/${fid}/permissions`, data)
  },
  updateFolderPermission(pid, fid, xid, data) {
    return Api().put(`procedure-folder/${pid}/folder/${fid}/permissions/${xid}`, data)
  },
  deleteFolderPermission(pid, fid, xid) {
    return Api().delete(`procedure-folder/${pid}/folder/${fid}/permissions/${xid}`)
  },
  getGroupFolderPermissions(gid, fid) {
    return Api().get(`group-folder/${gid}/folder/${fid}/permissions`)
  },
  setGroupFolderPermission(gid, fid, data) {
    return Api().post(`group-folder/${gid}/folder/${fid}/permissions`, data)
  },
  updateGroupFolderPermission(gid, fid, pid, data) {
    return Api().put(`group-folder/${gid}/folder/${fid}/permissions/${pid}`, data)
  },
  deleteGroupFolderPermission(gid, fid, pid) {
    return Api().delete(`group-folder/${gid}/folder/${fid}/permissions/${pid}`)
  },
  changeSubscription(sid, data) {
    return Api().post(`subscription/${sid}/upgrade`, data)
  },

  /* PLUGIN VERSION ROUTES */

  createPluginProcedure(id, data) {
    return Api().post('ext/procedures', data)
  },
  createPluginDocProcedure(id, data) {
    return Api().post(`ext/procedures/${id}/documents`, data)
  },
  createPluginFolderProcedure(id, data) {
    return Api().post(`ext/procedure-folder/${id}`, data)
  },
  editPluginFolder(id, fid, data) {
    return Api().put(`ext/procedure-folder/${id}/folder/${fid}`, data)
  },
  destroyPluginFolder(id, fid, data) {
    return Api().delete(`ext/procedure-folder/${id}/folder/${fid}`, data)
  },
  editPluginDocument(id, did, data) {
    return Api().put(`ext/procedures/${id}/documents/${did}`, data)
  },
  destroyPluginDocument(id, did, data) {
    return Api().delete(`ext/procedures/${id}/documents/${did}`, data)
  },
  getPluginDocumentFileThumb(id, did, fid) {
    return Api().get(`ext/procedures/${id}/documents/${did}/files/${fid}/thumb`)
  },
  destroyPluginFile(id, did, fid, data) {
    return Api().delete(`ext/procedures/${id}/documents/${did}/files/${fid}`, data)
  },
  createDocGroup(data) {
    return Api().post('ext/group-documents', data)
  },
  editGroupDocument(id, did, data) {
    return Api().put(`ext/group-documents/${did}`, data)
  },
  destroyGroupDocument(did, data) {
    return Api().delete(`ext/group-documents/${did}`, data)
  },
  createPluginFolderGroup(id, data) {
    return Api().post(`ext/group-folder/${id}`, data)
  },
  editPluginFolderGroup(id, fid, data) {
    return Api().put(`ext/group-folder/${id}/folder/${fid}`, data)
  },
  destroyPluginFolderGroup(id, fid, data) {
    return Api().delete(`ext/group-folder/${id}/folder/${fid}`, data)
  },
  editGroupFile(fid, data) {
    return Api().put(`ext/group-files/${fid}`, data)
  },
  destroyGroupFile(fid, data) {
    return Api().delete(`ext/group-files/${fid}`, data)
  },
}
