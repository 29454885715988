import Api from '../../plugins/baseAPI'

const state = {
    tickets: [],
    ticketCategories: [],
};

const getters = {
    ticketsList: state => state.tickets,
    ticketCategories: state => state.ticketCategories
};

const actions = {
    getTickets({commit}, user) {
        return new Promise((resolve, reject) => {
            Api().get("tickets", user)
                .then(resp => {
                    commit('setTickets', resp.data)
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getTicket({commit}, tid) {
        return new Promise((resolve, reject) => {
            Api().get("ticket/"+tid)
                .then(resp => {
                    commit('updateTicket', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getTicketCategories({commit}, user) {
        return new Promise((resolve, reject) => {
            Api().get("ticket-categories", user)
                .then(resp => {
                    commit('setTicketCategories', resp.data)
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
};

const mutations = {
    setTickets(state, payload) {
        state.tickets = payload
    },
    updateTicket(state, payload) {
        const item = state.tickets.find(item => item.id === payload.id);
        Object.assign(item, payload);
    },
    setTicketCategories(state, payload) {
        state.ticketCategories = payload
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};