import axios from 'axios'
import Api from '../../plugins/baseAPI'

const state = {
  token: '',
  apiKey: '',
}

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token,
  apiKey: state => state.apiKey,
}

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      Api().post('login', user)
        .then(resp => {
          localStorage.removeItem('lmpo-store')
          commit('setToken', resp.data.access_token)
          axios.defaults.headers.common.Authorization = `Bearer ${resp.data.access_token}`
          resolve(resp)
        })
        .catch(err => {
          commit('authLogout')
          reject(err)
        })
    })
  },
  loginToken({ commit }, token) {
    localStorage.removeItem('lmpo-store')
    commit('setToken', token)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  register({ commit }, data) {
    return new Promise((resolve, reject) => {
      Api().post('register', data)
        .then(resp => {
          commit('setToken', resp.data.access_token)
          resolve(resp)
        })
        .catch(err => {
          commit('authLogout')
          reject(err)
        })
    })
  },
  refreshToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      Api().post('refresh', token)
        .then(resp => {
          commit('setToken', resp.data.access_token)
          axios.defaults.headers.common.Authorization = `Bearer ${resp.data.access_token}`
          resolve(resp)
        })
        .catch(err => {
          commit('authLogout')
          reject(err)
        })
    })
  },
  logout: ({ commit }) => {
    commit('authLogout')
  },
  setApiKey({ commit }, apiKey) {
    commit('setApiKey', apiKey)
  },
}

const mutations = {
  setToken: (state, resp) => {
    state.token = resp
  },
  authLogout: state => {
    state.token = null
    state.apiKey = null
  },
  setApiKey: (state, apiKey) => {
    state.apiKey = apiKey
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
