import {Ability, defineAbility} from '@casl/ability'
import { initialAbility, getUserAbility } from './config'
import store from '@/store'

let userAbility = []

if(store.getters.loggedUser) {
  userAbility = getUserAbility(store.getters.loggedUser.role)
}
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
//const existingAbility = JSON.parse(localStorage.getItem('userAbility'))

export default getUserAbility(store.getters.loggedUser.role)
//export default new Ability(userAbility || initialAbility)

/*export default defineAbility((can, cannot) => {
  can('read', 'DashboardMenu');
  cannot('delete', 'Post', { published: true });
});*/
