import axios from 'axios'
import store from '@/store'
import { apiEnvUrl } from '@/configs/api'

export default () => {
  const { token } = store.getters
  axios.defaults.baseURL = apiEnvUrl
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  axios.interceptors.response.use(undefined, error => {
    if (error.response.status === 401) {
      store.dispatch('logout')

      return Promise.reject(error)
    }

    return Promise.reject(error)
  })

  return axios
}
