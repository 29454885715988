import Api from '../../plugins/baseAPI'
import PluginApi from '../../plugins/pluginAPI'

const state = {
  procedures: [],
  archivedProcedures: [],
  procedureCounters: [],
  usersAvatarList: [],
  companiesAvatarList: [],
  customersList: [],
}

const getters = {
  procedureList: state => state.procedures,
  archivedProcedureList: state => state.archivedProcedures,
  procedureCounters: state => state.procedureCounters,
  companyAvatarList: state => state.companiesAvatarList,
  userAvatarList: state => state.usersAvatarList,
  customersList: state => state.customersList,
}

const actions = {
  getProcedures({ commit }, user) {
    return new Promise((resolve, reject) => {
      Api().get('procedures', user)
        .then(resp => {
          commit('setProcedures', resp.data.procedures)
          commit('setArchivedProcedures', resp.data.archivedProcedures)
          commit('setProcedureCounters', resp.data.procedureCounters)
          commit('setCompanyAvatarList', resp.data.procedureAvatars.companies)
          commit('setUserAvatarList', resp.data.procedureAvatars.users)
          commit('setCustomersList', resp.data.procedureObjects.customers)

          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProcedure({ commit }, id) {
    return new Promise((resolve, reject) => {
      Api().get(`procedures/${id}`)
        .then(resp => {
          commit('updateProcedure', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPluginProcedure({ commit }, id) {
    return new Promise((resolve, reject) => {
      // pass the ak parameter to the api
      Api().get(`/ext/procedures/${id}`)
        .then(resp => {
          commit('setPluginProcedure', resp.data)
          resolve(resp)
        })
        .catch(err => {
          if (err.response.status === 404) {
            const error = {
              status: 404,
              message: 'Procedure not found',
            }
            reject(error)
          } else {
            reject(err)
          }
        })
    })
  },
  getOtherCompanyAvatar({ commit }, cid) {
    return new Promise((resolve, reject) => {
      Api().get(`company-avatar/${cid}`)
        .then(resp => {
          const data = {
            company_id: cid,
            path: resp.data,
          }

          commit('setCompanyAvatarUrl', data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOtherUserAvatar({ commit }, uid) {
    return new Promise((resolve, reject) => {
      Api().get(`user-avatar/${uid}`)
        .then(resp => {
          const data = {
            user_id: uid,
            path: resp.data,
          }
          commit('setUserAvatarUrl', data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAvatars({ state, dispatch }, firstRun = false) {
    // get all companies avatar
    if (state.companiesAvatarList.length > 0) {
      if (firstRun) {
        state.companiesAvatarList.forEach(async el => {
          await dispatch('getOtherCompanyAvatar', el.company_id)
        })
      } else {
        state.companiesAvatarList.filter(url => url.length === 0).forEach(async el => {
          await dispatch('getOtherCompanyAvatar', el.company_id)
        })
      }
    }

    // get all users avatar
    if (state.usersAvatarList.length > 0) {
      if (firstRun) {
        state.usersAvatarList.forEach(async el => {
          await dispatch('getOtherUserAvatar', el.user_id)
        })
      } else {
        state.usersAvatarList.filter(url => url.length === 0).forEach(async el => {
          await dispatch('getOtherUserAvatar', el.user_id)
        })
      }
    }
  },
}

const mutations = {
  setProcedures(state, payload) {
    state.procedures = payload
  },
  setPluginProcedure(state, payload) {
    // the payload is the single procedure, check if it is already in the array and if not add it, else update it
    const item = state.procedures.find(item => item.ext_id === payload.ext_id)
    if (item === undefined) {
      state.procedures.push(payload)
    } else {
      Object.assign(item, payload)
    }
  },
  setArchivedProcedures(state, payload) {
    state.archivedProcedures = payload
  },
  setProcedureCounters(state, payload) {
    state.procedureCounters = payload
  },
  updateProcedure(state, payload) {
    const item = state.procedures.find(item => item.id === payload.id)
    Object.assign(item, payload)
  },
  setCompanyAvatarList(state, payload) {
    const reorder = Object.values(payload)
    reorder.forEach((v, k) => {
      if (state.companiesAvatarList.find(({ company_id }) => company_id === v.company_id) === undefined) {
        state.companiesAvatarList.push({ company_id: v.company_id, path: v.path, key: k })
      }
    })
  },
  setCompanyAvatarUrl(state, payload) {
    const elSelected = state.companiesAvatarList.find(({ company_id }) => company_id === payload.company_id)
    state.companiesAvatarList[elSelected.key] = { company_id: payload.company_id, path: elSelected.path, url: payload.path }
  },
  setUserAvatarList(state, payload) {
    const reorder = Object.values(payload)
    reorder.forEach((v, k) => {
      if (state.usersAvatarList.find(({ user_id }) => user_id === v.user_id) === undefined) {
        state.usersAvatarList.push({ user_id: v.user_id, path: v.path, key: k })
      }
    })
  },
  setUserAvatarUrl(state, payload) {
    const elSelected = state.usersAvatarList.find(({ user_id }) => user_id === payload.user_id)
    state.usersAvatarList[elSelected.key] = { user_id: payload.user_id, path: elSelected.path, url: payload.path }
  },
  setCustomersList(state, payload) {
    state.customersList = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
