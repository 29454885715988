import Api from '../../plugins/baseAPI'

const state = {
    documents: []
};

const getters = {
    defaultDocumentsList: state => state.documents
};

const actions = {
    getDefaultDocuments({commit}) {
        return new Promise((resolve, reject) => {
            Api().get("default-documents")
                .then(resp => {
                    commit('setDefaultDocuments', resp.data)
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getDefaultDocument({commit}, id) {
        return new Promise((resolve, reject) => {
            Api().get("default-documents/"+id)
                .then(resp => {
                    commit('updateDefaultDocument', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    setDefaultDocuments(state, payload) {
        state.documents = payload
    },
    updateDefaultDocument(state, payload) {
        const item = state.documents.find(item => item.id === payload.id);
        Object.assign(item, payload);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};