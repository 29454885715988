import Api from '../../plugins/baseAPI'

const state = {
  partnerSettings: {},
}

const getters = {
  partnerSettings: state => state.partnerSettings,
}

const actions = {
  getPartnerSettings({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('/ext/partner-settings')
        .then(resp => {
          commit('setPartnerSettings', resp.data.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setPartnerSettings: (state, resp) => {
    state.partnerSettings = resp
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
