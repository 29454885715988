import Api from '../../plugins/baseAPI'

const state = {
    orders: [],
    orderCounters: [],
};

const getters = {
    orderList: state => state.orders,
    orderCounters: state => state.orderCounters,
};

const actions = {
    getOrders({commit}, user) {
        return new Promise((resolve, reject) => {
            Api().get("orders", user)
                .then(resp => {
                    commit('setOrders', resp.data.orders)
                    commit('setOrderCounters', resp.data.orderCounters)
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getOrder({commit}, oid) {
        return new Promise((resolve, reject) => {
            Api().get("order/"+oid)
                .then(resp => {
                    commit('updateOrder', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    setOrders(state, payload) {
        state.orders = payload
    },
    setOrderCounters(state, payload) {
        state.orderCounters = payload
    },
    updateOrder(state, payload) {
        const item = state.orders.find(item => item.id === payload.id);
        Object.assign(item, payload);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};