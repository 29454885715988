import Api from '../../plugins/baseAPI'

const state = {
    documents: []
};

const getters = {
    userDocumentsList: state => state.documents
};

const actions = {
    getUserDocuments({commit}) {
        return new Promise((resolve, reject) => {
            Api().get("user-documents")
                .then(resp => {
                    commit('setUserDocuments', resp.data)
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getUserDocument({commit}, id) {
        return new Promise((resolve, reject) => {
            Api().get("user-documents/"+id)
                .then(resp => {
                    commit('updateUserDocument', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    setUserDocuments(state, payload) {
        state.documents = payload
    },
    updateUserDocument(state, payload) {
        const item = state.documents.find(item => item.id === payload.id);
        console.log(item)
        Object.assign(item, payload);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};