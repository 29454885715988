import {defineAbility} from "@casl/ability";

export const initialAbility = [
  {
    action: 'read',
    subject: 'Public',
  },
  {
    action: 'read',
    subject: 'User',
  }
]

export const getUserAbility = (role) => defineAbility((can, cannot) => {
  switch(role) {
    case 'professional':
      can('read', 'DashboardMenu')

      can('read', 'ProceduresMenu')
      can('read', 'ProceduresMenuActive')
      can('read', 'ProceduresMenuArchived')

      can('read', 'ProceduresTemplateMenu')
      can('read', 'ProceduresTemplateMenuActive')

      can('read', 'ArchiveMenu')
      can('read', 'ArchiveCompanyDocumentsMenu')
      can('read', 'ArchiveGroupDocumentsMenu')
      cannot('read', 'ArchiveUserDocumentsMenu')
      can('read', 'ArchiveCustomersMenu')

      can('read', 'SubscriptionMenu')
      can('read', 'SubscriptionLicenseMenu')
      can('read', 'SubscriptionOrdersMenu')
      can('read', 'SubscriptionInvoicesMenu')

      can('read', 'SettingsMenu')
      can('read', 'SettingsProfileMenu')
      can('read', 'SettingsCompanyMenu')
      can('read', 'SettingsConfigsMenu')

      can('read', 'SupportMenu')
      can('read', 'SupportTicketsMenu')
      break
    case 'user':
      can('read', 'DashboardMenu')

      can('read', 'ProceduresMenu')
      can('read', 'ProceduresMenuActive')
      can('read', 'ProceduresMenuArchived')

      cannot('read', 'ProceduresTemplateMenu')
      cannot('read', 'ProceduresTemplateMenuActive')

      can('read', 'ArchiveMenu')
      cannot('read', 'ArchiveCompanyDocumentsMenu')
      can('read', 'ArchiveUserDocumentsMenu')
      cannot('read', 'ArchiveCustomersMenu')

      cannot('read', 'SubscriptionMenu')
      cannot('read', 'SubscriptionLicenseMenu')
      cannot('read', 'SubscriptionOrdersMenu')
      cannot('read', 'SubscriptionInvoicesMenu')

      can('read', 'SettingsMenu')
      can('read', 'SettingsProfileMenu')
      cannot('read', 'SettingsCompanyMenu')
      cannot('read', 'SettingsConfigsMenu')

      can('read', 'SupportMenu')
      can('read', 'SupportTicketsMenu')
      break
  }
})

export const _ = undefined
