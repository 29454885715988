import Api from '../../plugins/baseAPI'

const state = {
  documents: [],
}

const getters = {
  companyDocumentsList: state => state.documents,
}

const actions = {
  getCompanyDocuments({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('company-documents')
        .then(resp => {
          commit('setCompanyDocuments', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCompanyDocument({ commit }, id) {
    return new Promise((resolve, reject) => {
      Api().get(`company-documents/${id}`)
        .then(resp => {
          commit('updateCompanyDocument', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setCompanyDocuments(state, payload) {
    state.documents = payload
  },
  updateCompanyDocument(state, payload) {
    const item = state.documents.find(item => item.id === payload.id)
    Object.assign(item, payload)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
