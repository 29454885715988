const state = {
  imagesRepo: {
    userAvatars: [],
    procedureFilesThumb: [],
  },
}

const getters = {
  getImagesRepo: state => state.imagesRepo,
  getImagePath: state => (repo, id) => {
    try {
      if (state.imagesRepo[repo][id]) {
        const img = state.imagesRepo[repo][id]
        const date = new Date()
        if (Math.floor(date.getTime() / 1000) > img.expires) {
          this.askImagePath(repo, id)
        } else {
          return img.url
        }
      } else {
        this.askImagePath()
      }
    } catch (error) {
      console.log(error)
    }
  },
  askImagePath(repo, id) {
    try {
      const resp = this.$api.getUserAvatar(id)

      this.dispatch('setImageRepo', {
        repo,
        id,
        imageUrl: resp.data.img,
        expires: resp.data.expires,
      })

      return resp.data.img
    } catch (error) {
      console.log(error)
    }
  },
}

const actions = {
  setImageRepo({ commit }, payload) {
    commit('setImageRepoMutation', {
      repo: payload.repo, id: payload.id, url: payload.imageUrl, expires: payload.expires,
    })
  },
}

const mutations = {
  setImageRepoMutation: (state, payload) => {
    state.imagesRepo[payload.repo][payload.id] = {
      url: payload.url,
      expires: payload.expires,
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
