import Api from '../../plugins/baseAPI'

const state = {
    procedure_templates: []
};

const getters = {
    procedureTemplateList: state => state.procedure_templates,
};

const actions = {
    getProcedureTemplates({commit}, user) {
        return new Promise((resolve, reject) => {
            Api().get("procedure-templates", user)
                .then(resp => {
                    commit('setProcedureTemplates', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getProcedureTemplate({commit}, id) {
        return new Promise((resolve, reject) => {
            Api().get("procedure-template/"+id)
                .then(resp => {
                    commit('updateProcedureTemplate', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

};

const mutations = {
    setProcedureTemplates(state, payload) {
        state.procedure_templates = payload
    },
    updateProcedureTemplate(state, payload) {
        const item = state.procedure_templates.find(item => item.id === payload.id);
        Object.assign(item, payload);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};