import Api from '../../plugins/baseAPI'

const state = {
  settings: [],
}

const getters = {
  companySettingsList: state => state.settings,
  companySettingsFilteredList: state => settingType => state.settings[settingType].company_setting_values,
}

const actions = {
  getCompanySettings({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('company-settings')
        .then(resp => {
          commit('setCompanySettings', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPluginCompanySettings({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('ext/company-settings')
        .then(resp => {
          commit('setCompanySettings', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setCompanySettings(state, payload) {
    state.settings = payload

    const tmp = {}

    Object.keys(state.settings).map(key => {
      tmp[state.settings[key].setting_type] = state.settings[key]
    })

    state.settings = tmp
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
