import Api from '../../plugins/baseAPI'

const state = {
  group: {},
  groupFolders: [],
  subgroups: {},
  companies: {},
  users: {},
}

const getters = {
  group: state => state.group,
  groupFolders: state => state.groupFolders,
  groupSubgroupsList: state => state.subgroups,
  groupCompaniesList: state => state.companies,
  groupUsersList: state => state.users,
}

const actions = {
  getGroup({ commit }) {
    return new Promise((resolve, reject) => {
      Api().get('group')
        .then(resp => {
          commit('setGroup', resp.data.data)
          commit('setGroupFolders', resp.data.data.folders)
          commit('setSubgroups', resp.data.data.subgroups)
          commit('setCompanies', resp.data.data.companies)
          commit('setUsers', resp.data.data.users)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

const mutations = {
  setGroup(state, payload) {
    state.group = payload
  },
  setGroupFolders(state, payload) {
    state.groupFolders = payload
  },
  setSubgroups: (state, resp) => {
    state.subgroups = resp
  },
  setCompanies: (state, resp) => {
    state.companies = resp
  },
  setUsers: (state, resp) => {
    state.users = resp
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
